import React from "react";
import { Typography } from "@mui/material";
import ThirdFieldRow from "./ThirdFieldRow";

interface IProps {
  thirdQuestion: {
    company_name: string;
    company_postcode: string;
    company_address: string;
    pic_name: string;
    pic_contact: string;
  };
  setThirdQuestion: React.Dispatch<
    React.SetStateAction<{
      company_name: string;
      company_postcode: string;
      company_address: string;
      pic_name: string;
      pic_contact: string;
    }>
  >;
}

const ThirdField = (Props: IProps) => {
  const textChangeHandler = (name: string, data: string) => {
    Props.setThirdQuestion((prevState) => {
      return {
        ...prevState,
        [name]: data,
      };
    });
  };
  return (
    <div className="FieldDiv">
      <Typography variant="h6" component="div" align="left" sx={{ ml: 3 }}>
        ● ご連絡先
      </Typography>
      <Typography variant="caption" component="div" align="left" sx={{ ml: 6 }}>
        以下の連絡先を入力してください
      </Typography>
      <ThirdFieldRow
        label="貴社名"
        index="company_name"
        inputVal={Props.thirdQuestion.company_name}
        textChange={textChangeHandler}
      />
      <ThirdFieldRow
        label="郵便番号"
        index="company_postcode"
        inputVal={Props.thirdQuestion.company_postcode}
        textChange={textChangeHandler}
      />
      <ThirdFieldRow
        label="所在地"
        index="company_address"
        inputVal={Props.thirdQuestion.company_address}
        textChange={textChangeHandler}
      />
      <ThirdFieldRow
        label="ご担当者氏名"
        index="pic_name"
        inputVal={Props.thirdQuestion.pic_name}
        textChange={textChangeHandler}
      />
      <ThirdFieldRow
        label="ご担当者連絡先"
        index="pic_contact"
        inputVal={Props.thirdQuestion.pic_contact}
        textChange={textChangeHandler}
      />
    </div>
  );
};

export default React.memo(ThirdField);
