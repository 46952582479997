import React, { SetStateAction } from "react";
import {
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

interface IProps {
  firstQuestionState: string;
  setFirstQuestion: React.Dispatch<SetStateAction<string>>;
  secondQuestionState: string;
  setSecondQuestion: React.Dispatch<SetStateAction<string>>;
}

const FirstField = ({
  firstQuestionState,
  setFirstQuestion,
  secondQuestionState,
  setSecondQuestion,
}: IProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstQuestion(event.target.value);
  };

  return (
    <div className="FieldDiv">
      <Typography variant="h6" component="div" align="left" sx={{ ml: 3 }}>
        ● 適格請求書発行事業者登録手続きの状況 (いずれかにチェック)
      </Typography>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={firstQuestionState}
        onChange={handleChange}
      >
        <FormControlLabel
          value="registered"
          control={<Radio />}
          label="適格請求書発行事業者登録済"
          sx={{ ml: 5 }}
        />
        {firstQuestionState === "registered" ? (
          <TextField
            sx={{ flex: "2" }}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography sx={{ fontSize: 18, padding: "0.5vh" }}>
                    T
                  </Typography>
                </InputAdornment>
              ),
              style: { height: 50, fontSize: 18, margin: "0 10vh" },
            }}
            value={secondQuestionState}
            onChange={(e) => setSecondQuestion(e.target.value)}
            placeholder="登録番号"
          />
        ) : null}
        <FormControlLabel
          value="notyet"
          control={<Radio />}
          label="現在は登録していないが、今後手続き予定"
          sx={{ ml: 5 }}
        />
        <FormControlLabel
          value="notregistered"
          control={<Radio />}
          label="登録なし（今後も登録予定なし)"
          sx={{ ml: 5 }}
        />
      </RadioGroup>
    </div>
  );
};

export default React.memo(FirstField);
