import { Alert, Button, TextField } from "@mui/material";
import React, { useEffect, useRef } from "react";
import "./App.css";
import logo from "./assets/logo.svg";
import { useLocation, useNavigate } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    if (inputRef.current) navigate(`/${inputRef.current.value}`);
  };
  const inputRef = useRef<HTMLInputElement>(null);

  //clear state on page reload
  useEffect(() => {
    navigate(location.pathname, {});
  }, []);
  return (
    <div className="App">
      <div className="TitleDiv">
        <a href="https://www.mirai.ad.jp/">
          <img
            src={logo}
            height="60px"
            alt="MiraiNet"
            style={{ cursor: "pointer" }}
          />
        </a>
      </div>
      <form className="LoginDiv" onSubmit={submitHandler}>
        <div style={{ fontSize: 30 }}>番号ログイン</div>
        <div style={{ padding: "1vh 0" }}>番号を入力してください</div>
        {location.state && (
          <Alert sx={{ m: 3 }} severity="error">
            {location.state}
          </Alert>
        )}
        <TextField
          id="standard-basic"
          variant="standard"
          sx={{ pt: "10vh", pb: "5vh", width: "250px" }}
          inputRef={inputRef}
          type="number"
        />
        <div></div>
        <Button variant="contained" type="submit">
          次へ
        </Button>
      </form>
    </div>
  );
}
