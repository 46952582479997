import { Alert, Button, Card, CardContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./App.css";
import logo from "./assets/logo.svg";
import FirstField from "./components/FirstField";
// import SecondField from "./components/SecondField";
import ThirdField from "./components/ThirdField";
import Title from "./components/Title";
import { useNavigate, useParams } from "react-router-dom";
// import CustomerInfo from "./components/CustomerInfo";

function App() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [alertStatus, setAlertStatus] = useState<
    "success" | "error" | "nothing"
  >("nothing");
  const [errorContent, setErrorContent] = useState("");

  useEffect(() => {
    //if not a number
    if (userId && !/^\d+$/.test(userId)) {
      navigate("/");
    }
    fetch(`https://iv.mirai.ad.jp/api/single_read.php?id=${userId}`, {
      mode: "cors",
      // fetch(`/api/single_read.php?id=${userId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          // setCustomerID(data.customer_id ? data.customer_id : "");
          firstQSetState(data.registration);
          secondQSetState(data.registration_number);
          thirdQSetState({
            company_name: data.company_name,
            company_postcode: "",
            company_address: "",
            pic_name: "",
            pic_contact: "",
            // company_postcode: data.company_postcode,
            // company_address: data.company_address,
            // pic_name: data.pic_name,
            // pic_contact: data.pic_contact,
          });
        } else navigate("/", { state: "ユーザが存在しません" });
      })
      .catch(() => {
        console.log("test");
        navigate("/", { state: "ユーザが存在しません" });
      });
    // eslint-disable-next-line
  }, [userId]);

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Registration number must be 14 characters long
    if (firstQState === "registered" && !secondQState.length) {
      setAlertStatus("error");
      setErrorContent("登録番号が無効です");
    } else if (!thirdQState.company_name.length) {
      setAlertStatus("error");
      setErrorContent("貴社名が無効です");
    } else {
      fetch(`https://iv.mirai.ad.jp/api/update.php`, {
        mode: "cors",
        // fetch(`/api/update.php`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: userId,
          registration: firstQState,
          registration_number: firstQState === "registered" ? secondQState : "",
          company_name: thirdQState.company_name,
          company_postcode: thirdQState.company_postcode,
          company_address: thirdQState.company_address,
          pic_name: thirdQState.pic_name,
          pic_contact: thirdQState.pic_contact,
        }),
      })
        .then((response) => {
          if (response.ok) setAlertStatus("success");
          else return Promise.reject();
        })
        .catch(() => {
          setAlertStatus("error");
          setErrorContent(
            "登録に失敗しました。時間をおいてから再度登録してみてください。"
          );
        });
    }
  };

  //questions states
  // const [customerID, setCustomerID] = useState("");
  const [firstQState, firstQSetState] = useState("registered");
  const [secondQState, secondQSetState] = useState<string>("");
  const [thirdQState, thirdQSetState] = useState({
    company_name: "",
    company_address: "",
    company_postcode: "",
    pic_name: "",
    pic_contact: "",
  });

  return (
    <div className="App">
      <div className="TitleDiv">
        <a href="https://www.mirai.ad.jp/">
          <img
            src={logo}
            height="60px"
            alt="MiraiNet"
            style={{ cursor: "pointer" }}
          />
        </a>
      </div>
      <form className="FormDiv" onSubmit={formSubmit}>
        <Title />
        <Card className="CardField">
          <CardContent>
            {alertStatus === "success" && (
              <Alert severity="success">受付完了いたしました。</Alert>
            )}
            {alertStatus === "error" && (
              <Alert severity="error">{errorContent}</Alert>
            )}
            {/* <CustomerInfo customer_id={customerID} /> */}
            <FirstField
              firstQuestionState={firstQState}
              setFirstQuestion={firstQSetState}
              secondQuestionState={secondQState}
              setSecondQuestion={secondQSetState}
            />
            <ThirdField
              thirdQuestion={thirdQState}
              setThirdQuestion={thirdQSetState}
            />
            <Button variant="contained" type="submit" sx={{ mt: 2 }}>
              確認
            </Button>
          </CardContent>
        </Card>
      </form>
    </div>
  );
}

export default App;
