import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

const Title = () => {
  return (
    <Card className="CardTitle">
      <CardContent>
        <Typography variant="h5" component="div">
          適格請求書発行事業者登録番号通知書
        </Typography>
      </CardContent>
    </Card>
  );
};
export default React.memo(Title);
