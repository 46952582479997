import { TextField } from "@mui/material";
import React from "react";
import { PatternFormat } from "react-number-format";

interface IProps {
  label: string;
  index: string;
  inputVal: string;
  textChange: (name: string, data: string) => void;
}

const ThirdFieldRow = ({ label, index, inputVal, textChange }: IProps) => {
  return (
    <div
      style={{
        display: "flex",
        width: "90%",
        margin: "1vh 5vh",
      }}
    >
      <div
        style={{
          fontSize: 20,
          height: "32px",
          width: "150px",
          // border: "1px solid #CBCBCB",
          // borderRadius: "5px",
          alignSelf: "center",
          textAlign: "right",
          padding: "0.4rem 0 0 1rem",
          marginRight: "3vh",
          fontWeight: "bold",
        }}
      >
        {label}
      </div>
      {index !== "company_postcode" ? (
        <TextField
          id="outlined-basic"
          variant="outlined"
          size="small"
          value={inputVal ? inputVal : ""}
          onChange={(e) => textChange(index, e.target.value)}
          sx={{ flex: 1 }}
          placeholder={`${label}`}
        />
      ) : (
        <PatternFormat
          value={inputVal ? inputVal : ""}
          format="### - ####"
          customInput={TextField}
          id="outlined-basic"
          variant="outlined"
          size="small"
          onChange={(e) => textChange(index, e.target.value)}
          sx={{ flex: 1 }}
          placeholder={`${label}`}
        />
      )}
    </div>
  );
};

export default React.memo(ThirdFieldRow);
